<template>
    <div class="flex flex-column">
        <div class="flex">
            <div class="flex-grow-1">
                <h2 class="blue clarity-margin-bottom-m">EMAIL VERIFIED</h2>
            </div>
        </div>
        <div class="flex">
            <img class="verifiedImg" src="@/assets/img/verifiedLogo.png" alt="Account Verified">
        </div>
        <div v-if="verified"  class="flex flex-column clarity-margin-top-s clarity-margin-bottom-s">
            <p class="body-text">All done! Your email address has been successfully verified.</p>
            <p class="body-text">Please continue to login.</p>
        </div>
        <div class="clarity-margin-top-m clarity-margin-bottom-m" v-else>
            <p class="body-text">Verification Error please contact us.</p>
        </div>
        <div class="flex">
            <Button label="Continue" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" @click="goToLogin"></Button>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { Auth } from '@aws-amplify/auth';
import api from '@/core/services/api';
import {toClarityAnonRequest} from "../core/helpers";

export default {
    setup() {
        const router = useRouter();
        const code = ref();
        const username = ref();
        const verified = ref(false);

        const goToLogin = () => {
            router.push('/');
        }

        onMounted(async () => {
            const URI = decodeURIComponent(window.location.href);
            const params = new URL(URI).searchParams;
            const encodedUsername = params.get('u');
            const decodedUsername = Buffer.from(encodedUsername, 'base64').toString('utf8');
            code.value = params.get('c');
            username.value = decodedUsername;

            try {
                await Auth.confirmSignUp(username.value,code.value);
                verified.value = true;
                // Fire off D9 function to set clarity account to active.
                //console.log('Username: '+username.value);
                const endpoint = 'clientdirectory/newclientregistration';
                const response = await api.patch(`${toClarityAnonRequest(endpoint)}`, JSON.stringify({'username':username.value}), {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                console.log(response);
            } catch (err) {
                console.log(err);
            }
        });

        return {
            goToLogin,
            code,
            username,
            verified
        }
    }
}

</script>

<style scoped lang="scss">
    .verifiedImg {
        width: 100%;
        height: 320px;
        border: 1px solid var(--clarity-pure-white);
        box-shadow: 0px 5px 10px #0000001A;
        border-radius: 27px;
    }
    .body-text {
        margin: 0 !important;
    }
    button {
        width: 300px !important;
    }
</style>
